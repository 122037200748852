import React from 'react';
import { useContext, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import noteContext from '../Context/notes/noteContext';
import { Button } from 'flowbite-react';
import logo from "./assests/giftedcitlogo-removebg-preview.png"
import { FaEye, FaEyeSlash } from 'react-icons/fa';

// Define a functional component for the Login page
export default function ForgotPass() {
  // Initialize React Router's navigation hook
  const navigate = useNavigate();
  // Access the 'host' property from the context using useContext
  const { host } = useContext(noteContext);

  // State to manage user input for email and password
  const [credentials, setCredentials] = useState({ email: ''});



  // Function to handle user sign-in
  const signin = async (e) => {
    try {
      e.preventDefault();
      // Fetch request to the server for user authentication
      const response = await fetch(`${host}/api/auth/forgot-password`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          email: credentials.email
        }),
      });
      // Parse the response as JSON
      let res = await response.json();
      // Check if the authentication was successful
      if (res.success) {
        // Store the authentication token in local storage
        // localStorage.setItem('authtoken', res.authtoken);
        // Navigate to the home page upon successful sign-in
        // navigate('/');
        alert("Email sent to your mail to reset password,Link Expires in 15 minutes")
      } else {
        // Display an alert for invalid details
        alert('Enter valid details');
      }
    } catch (error) {
      // Display any caught errors in the 'signin_error' element
      document.getElementById('signin_error').innerHTML = error;
    }
  };

  // Function to handle input changes and update state
  const handleOnChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  // Render the Login component
  return (
    <>
      {/* Container for the login form */}
      <div className='container  mt-1 mx-auto p-4 mb-7'>

        {/* Form for user login */}
        <form className='max-w-md mx-auto bg-white rounded-md overflow-hidden shadow-lg'>
          {/* Form content */}
          <div className='px-6 py-4'>
            {/* Logo and brand text */}
            <div className='nav-link right-btn flex justify-center items-center'>
              <img src={logo} alt="Logo" className="logo-image" />
            </div>
      
          
              <div className="logo-container nav-link right-btn flex justify-center items-center my-4">
                {/* <img src={logo} alt="Logo" className="logo-image" /> */}
                <div className="sibrand-text">
                  <span>  Forgot Password</span>
                </div>
              </div>
            {/* Email input field */}
            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='email'>
              Email address
            </label>
            <input
              type='email'
              name='email'
              className='w-full p-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring focus:border-blue-300'
              id='email'
              onChange={handleOnChange}
              placeholder='Enter email'
            />
            
            {/* Password input field */}
            {/* Sign-in button with a Link to trigger the sign-in function */}
            <Link className="nav-link right-btn flex justify-center items-center" onClick={signin}>
  <Button gradientDuoTone="pinkToOrange">Submit</Button>
</Link>


          </div>
        </form>

        {/* Display any sign-in errors */}
        <p id='signin_error' className='text-red-500 text-center mt-2'></p>
      </div>
    </>
  );
}
