import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import logo from "../assests/fimg1.jpg"
import logo2 from "../assests/fimg2.jpg"
import logo3 from '../assests/fimg3.jpg'
import logo4 from '../assests/fimg4.jpg'
import logo5 from "../assests/fimg5.jpg"
import logo6 from "../assests/DALL·E 2024-02-20 16.06.57 - Create an animated-style image of a gift box with a whimsical minia.jpg"
import logo7 from "../assests/img10.jpg"

const getRandomImage = () => {
  // Use an array of image URLs related to gifts
  const giftImages = [
    logo,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,

    // Add more image URLs as needed
  ];

  // Get a random index to pick a random image from the array
  const randomIndex = Math.floor(Math.random() * giftImages.length);

  return giftImages[randomIndex];
};

function OrderItem({ order, onEdit, onDelete }) {
  return (
    <div className='row'>
      <div className='col-md-2 mb-4'></div>
      <div className='col-md-6 mb-4'>
        <div className='card h-100 p-3 rounded-md shadow-md hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105'>
          <h5 className='text-lg font-semibold mb-2'>Recipient Name: {order.recipientName}</h5>
          <p className='text-gray-600'>Relationship: {order.relationship}</p>
          <p className='text-gray-600'>Occasion: {order.occasion}</p>
          <p className='text-gray-600'>Gender: {order.gender}</p>
          <p className='text-gray-600'>Address: {order.address}</p>
          <p className='text-gray-600'>Phone Number: {order.phoneNumber}</p>
          <p className='text-gray-600'>Email: {order.email}</p>
          <p className='text-gray-600'>Interests: {order.interests}</p>
          <p className='text-gray-600'>Budget: ${order.budget}</p>
          <p className='text-gray-600'>Default Option: {order.defaultOption}</p>
          <p className='text-gray-600'>Comments: {order.comments}</p>
          {order.date && order.date.length !== 0 && (
            <p className='text-gray-600'>Date: {new Date(order.date).toLocaleDateString()}</p>
          )}
          <div className='md:hidden'>
            {/* Image card for mobile view */}
            <div className='card p-2 rounded-md shadow-md hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105'>
              <div className='card-img-top'>
                <img
                  src={getRandomImage()}
                  alt='Random Gift'
                  className='w-full h-full object-cover rounded-md'
                />
              </div>
              <div className='card-body'>
                <h5 className="card-title">
                  <div className="flex items-center">
                    <div className="text-black">Gift for</div>
                    <div className="ml-2">{order.recipientName}</div>
                  </div>
                </h5>
                <p className='card-text'>
                  Your Order will Delivered by {new Date(order.date).toLocaleDateString()}
                </p>
              </div>
            </div>
          </div>

          <div className='sm:hidden flex justify-end mt-4'>
            <button
              className='text-blue-500 hover:text-blue-700 mr-3 focus:outline-none transition duration-300 ease-in-out transform hover:scale-105'
              onClick={() => onEdit(order)}
            >
              <FaEdit className='inline-block' />
              Edit
            </button>
            <button
              className='text-red-500 hover:text-red-700 focus:outline-none transition duration-300 ease-in-out transform hover:scale-105'
              onClick={() => onDelete(order._id)}
            >
              <FaTrash className='inline-block' />
              Delete
            </button>
          </div>
        </div>
      </div>
      <div className='hidden md:block col-md-2 mb-4 rounded-md shadow-md hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105 '>
        <div className="card p-2">
          <div className="card-img-top">
            <img
              src={getRandomImage()}
              alt="Random Gift"
              className="w-full h-full object-cover rounded-md"
            />
          </div>
          <div className="card-body">
            <h5 className="card-title">
              <div className="flex items-center">
                <div className="text-black">Gift for</div>
                <div className="ml-2">{order.recipientName}</div>
              </div>
            </h5>
            <p className="card-text">Your Order will Delivered by {new Date(order.date).toLocaleDateString()}</p>
          </div>
          <div className="flex justify-end mt-4">
            <button
              className="text-blue-500 hover:text-blue-700 mr-3 focus:outline-none transition duration-300 ease-in-out transform hover:scale-105"
              onClick={() => onEdit(order)}
            >
              <FaEdit className="inline-block" />
              Edit
            </button>
            <button
              className="text-red-500 hover:text-red-700 focus:outline-none transition duration-300 ease-in-out transform hover:scale-105"
              onClick={() => onDelete(order._id)}
            >
              <FaTrash className="inline-block" />
              Delete
            </button>
          </div>
        </div>

      </div>
    </div>
  );
}

export default OrderItem;
