import React, { useContext, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import noteContext from '../Context/notes/noteContext';
import { Button ,TextInput } from 'flowbite-react';
import logo from "./assests/giftedcitlogo-removebg-preview.png"
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export default function SignUp() {
  const navigate = useNavigate();
  const { host } = useContext(noteContext);

  const [credentials, setCredentials] = useState({ name: '', email: '', password: '' });

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const signup = async (e) => {
    try {
      e.preventDefault();
      const response = await fetch(`${host}/api/auth/createUser`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          name: credentials.name,
          email: credentials.email,
          password: credentials.password
        })
      });
      let res = await response.json();
      if (res.success) {
        localStorage.setItem('authtoken', res.authtoken);
        navigate('/orders');
      } else {
        alert('Email Already Exist');
      }
    } catch (error) {
      document.getElementById('signup_error').innerHTML = error;
    }
  };

  const handleOnChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <div className=' container mx-auto p-4 mb-7'>
      <form className='max-w-md mx-auto bg-white rounded-md overflow-hidden shadow-lg'>
        <div className='px-6 py-4'>
            <div className='nav-link right-btn flex justify-center items-center'>
              <img src={logo} alt="Logo" className="logo-image" />
            </div>
      
          
              <div className="logo-container nav-link right-btn flex justify-center items-center">
                {/* <img src={logo} alt="Logo" className="logo-image" />
                <br/> */}
                <div className="sibrand-text">
                  <span>  SIGN UP</span>
                </div>
              </div>
            
          <div className='mb-4'>
            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='name'>
              Name
            </label>
            <TextInput
              type='text'
              name='name'
              // className='w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300'
              id='name'
              onChange={handleOnChange}
              placeholder='Enter name'
            />
          </div>
          <div className='mb-4'>
            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='email'>
              Email address
            </label>
            <TextInput
              type='email'
              name='email'
              // className='w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300'
              id='email'
              onChange={handleOnChange}
              placeholder='Enter email'
            />
          </div>
          <div className='mb-6'>
            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='password'>
              Password
            </label>
            <div className='relative  w-full'>
  <TextInput
    type={showPassword ? 'text' : 'password'}
    name='password'
    
    id='password'
    onChange={handleOnChange}
    placeholder='Enter password'
    style={{ paddingRight: '40px' }} 
    // Ensure space for the eye icon
  />
  <button 
  type="button"
  onClick={togglePasswordVisibility} 
  className="absolute top-1/2 transform -translate-y-1/2 right-2"// Adjust top value to move the icon slightly below the center
>
  {showPassword ? <FaEyeSlash /> : <FaEye />}
</button>
</div>
          </div>

          <Link to='/signup' className='av-link right-btn flex justify-center items-center' onClick={signup}>
            <Button gradientDuoTone='pinkToOrange'>Sign Up</Button>
          </Link>
        </div>
      </form>
      <p id='signup_error' className='text-red-500 text-center mt-2'></p>
    </div>
  );
}
