import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import NoteState from './Context/notes/NoteState';
import Navbar from './Components/Navbar';
import NavbarAdmin from './Components/admin/NavbarAdmin';
import AdminOrders from './Components/admin/AdminOrders';
import Login from './Components/Login';
import SignUp from './Components/SignUp';
import Home from './Components/Home';
import Orders from './Components/OrderRelated/Orders';
import AdminLogin from './Components/admin/AdminLogin';
import Profile from './Components/Profile';
import { useState } from 'react';
import FooterComponent from './Components/FooterComponent';
// import MarqueeRow from "./Components/MarqueeRow"
import Error from "./Components/Error"
import AdminNote from './Components/admin/AdminNote';
import ForgotPass from './Components/ForgotPass';

function App() {
  const [adminView, setadminView] = useState("new");
  const location = useLocation();

  const showNavbar = ["/signin", "/signup", "/", "/orders", "/profile","/forgotpass"].includes(location.pathname);
  const showAdminNavBar = ["/adminorders","/requests"].includes(location.pathname);

  return (
    <div>
      <NoteState>
      <div className='flex flex-col min-h-screen'>
      
        {showNavbar && <Navbar />}
        {showNavbar && <div className='my-3'></div>}

        <Routes>
          <Route path="/signin" element={<Login />} />
          <Route path="/forgotpass" element={<ForgotPass />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/" element={<Home />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/profile" element={<Profile />} />
          {/* <Route path="*"  element={<Error/>} /> */}
        </Routes>

        {showAdminNavBar && <NavbarAdmin setadminView={setadminView} adminView={adminView} />}
        {showAdminNavBar && <div className='my-1'></div>}

        <Routes>
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/requests" element={<AdminNote />} />
          <Route path="/adminorders" element={<AdminOrders setadminView={setadminView} adminView={adminView} />} />
          {/* <Route path="*"  element={<Error/>} /> */}
        </Routes>
      
      </div>
      <FooterComponent/>
      </NoteState>
    </div>
  );
}

export default App;
