import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export default function CalendarTemplate({ dates, handleDateClick }) {
  const specialDays = dates;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const tileContent = ({ date, view }) => {
    const fdate = new Date(date);
    const year = fdate.getFullYear();
    const month = String(fdate.getMonth() + 1).padStart(2, '0');
    const day = String(fdate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const showText = isHovered && view === 'month' && specialDays[formattedDate];

    if (view === 'month' && specialDays[formattedDate]) {
      return (
        <div
          className="relative"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full w-4 h-4 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 shadow-md"
          ></div>
          {/* {showText && (
          <span className="absolute top-[-10px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs text-black font-bold">
            {specialDays[formattedDate].message}
          </span>
        )} */}
        </div>
      );
    }
  };

  return (
    <div className="p-4 flex justify-center"> {/* Center the calendar */}
      <Calendar
        tileContent={tileContent}
        onClickDay={handleDateClick}
        className="border rounded-md p-2 shadow-lg"
        style={{ maxWidth: '100%', width: '100%' }} 
      />
    </div>
  );
}
