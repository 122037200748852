import React, { useEffect, useState, useContext } from 'react';
import noteContext from '../../Context/notes/noteContext';
import OrderItem from './OrderItem';
import CalendarTemplate from './CalendarTemplate';
import { useNavigate } from 'react-router-dom';
import { TextInput, Button, Textarea } from 'flowbite-react';
import { FaRegUser } from "react-icons/fa";
import { TbCirclesRelation } from "react-icons/tb";
import { GiPartyPopper } from "react-icons/gi";

import { AiFillLike } from "react-icons/ai";
import { FaPhone, FaEnvelope, FaMoneyBillAlt, FaComment } from 'react-icons/fa';
import { FaBirthdayCake, FaGift } from 'react-icons/fa';
import { GiChocolateBar } from "react-icons/gi";
import { GiFlowerEmblem } from "react-icons/gi";
import { RxShadowNone } from "react-icons/rx";



export default function Orders() {
  const { host,checkProfile,isProfileUpdate } = useContext(noteContext);
  const [fadata, setFadata] = useState([]);
  const [editOrder, setEditOrder] = useState(null); // State to track the order being edited
  const [addOrder, setaddOrder] = useState(null); // State to track the order being added
  const [orderDatesAll, setorderDatesAll] = useState({})
  const [orderDate, setorderDate] = useState(null)
  const getCrtDate = (date) => {
    var fdate = new Date(date);

    // Get the date portion
    var year = fdate.getFullYear();
    var month = String(fdate.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
    var day = String(fdate.getDate()).padStart(2, '0');

    // Construct the date string in the desired format (YYYY-MM-DD)
    var formattedDate = year + '-' + month + '-' + day;
    return formattedDate;
  }
  const fetchAllOrders = async () => {
    try {

    const auth_token = localStorage.getItem('authtoken');
    const response = await fetch(`${host}/api/gifts/getgifts`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'auth-token': auth_token
      },
    });
    const fetchedData = await response.json();
    const formattedDates = {};
    fetchedData.forEach(item => {
      if (item.date.length != 0) {
        formattedDates[item.date] = {
          message: `${item.recipientName}'s ${item.occasion}`,
          color: item.occasion === 'Birthday' ? 'red' : 'blue' // Set color based on occasion
        };
      }
    });
    // console.log(formattedDates)
    // Update state with the formatted data
    setorderDatesAll(formattedDates);
    if (orderDate != null) {
      let data = filterDataByDate(fetchedData, orderDate)
      // console.log(data,fetchedData,formattedDate)
      setFadata(data);
    } else {
      setFadata(fetchedData);
    }

  } catch (error) {
    console.log(error)
  }
  };

  let navigate = useNavigate()
  const { checkUser } = useContext(noteContext);
  const checkUserFunction = async() => {
    let res=await checkUser();
    if (!res) {
      navigate("/signin")
    }
  }





 



  useEffect(() => {
    checkUserFunction();
    fetchAllOrders();
    checkProfile();
  }, []);

  

  const handleEditClick = (order) => {
    setEditOrder(order); // Set the order to be edited
    // setEditOrder({...order, date: order.date});
  };

  const handleUpdateOrder = async (updatedOrder) => {
    // Implement the API call to update the order here
    const auth_token = localStorage.getItem('authtoken');
    try {
      await fetch(`${host}/api/gifts/updateGift/${updatedOrder._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': auth_token
        },
        body: JSON.stringify(updatedOrder)
      });
      // Refresh the orders list to reflect the update
      fetchAllOrders();
    } catch (error) {
      console.error("Error updating the order:", error);
    }
    setEditOrder(null);
  };

  const handleDeleteOrder = async (orderId) => {
    // Implement the API call to delete the order here
    // Optionally refresh the order list after deletion
    const auth_token = localStorage.getItem('authtoken');
    try {
      await fetch(`${host}/api/gifts/deleteGift/${orderId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': auth_token
        }
      });
      // Refresh the orders list to reflect the deletion
      // setFadata(fadata.filter(order => order._id !== orderId));
      fetchAllOrders();

    } catch (error) {
      console.error("Error deleting the order:", error);
    }
  };


  const [formData, setFormData] = useState({
    recipientName: '',
    relationship: '',
    occasion: '',
    gender:"",
    address: '',
    phoneNumber: '',
    email: '',
    interests: '',
    budget: '',
    defaultOption: 'None',
    comments: '',
    date: '',
  });

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleOrder = async (e) => {
    try {

    // e.preventDefault();
    console.log('Form Data:', formData);

    // e.preventDefault(); // Prevent form submission default behavior

    const response = await fetch(`${host}/api/gifts/addgift`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "auth-token": localStorage.getItem("authtoken")
      },
      body: JSON.stringify(formData)
    });

    let res = await response.json();

    if (res.success) {
      alert("Successfully ordered");
      setFormData({
        recipientName: '',
        relationship: '',
        occasion: '',
        gender:"",
        address: '',
        phoneNumber: '',
        email: '',
        interests: '',
        budget: '',
        defaultOption: 'None',
        comments: '',
        date: '',
      });
      setaddOrder(null)
      fetchAllOrders();
      if(!isProfileUpdate){
      alert("Gift Added Successfully,Please Update Your Profile")
      navigate("/profile")
      }
    } else {
      alert("Fill all the necessary fields");
    }

  } catch (error) {
    console.log(error)
  }
  };
  const filterDataByDate = (data, date) => {
    return data.filter(item => {
      return item.date === date;
    });
  };

  const handleDateClick = (value) => {

    setorderDate(getCrtDate(value))


  };

  useEffect(() => {
    fetchAllOrders()

  }, [orderDate])

  const handleOrderPlusclick=()=>{
    // if(isProfileUpdate){
    setaddOrder(true)
    // }else{
    //   alert("Please Update Your Profile Before Ordering")
    //   navigate("/profile")
    // }
  }


  return (
    <div className="container justify-center">
      <div className='mx-auto  max-w-screen-sm p-4'>
        <div className="logos-container">
          {/* <img src={logo} alt="Logo" className="logo-image" /> */}
          <div className="ml-3 brand-texts">
            <span>Orders </span>
          </div>
        </div>
        <div className="row justify-content-center"> {/* Center the row horizontally */}
  <div className="col-6 col-md-auto mb-4 mr-md-3 d-flex justify-content-center"> {/* Adjust column size and margin for horizontal gap */}
    <Button gradientDuoTone="pinkToOrange" onClick={handleOrderPlusclick}>Add Order</Button>
  </div>
  <div className="col-6 col-md-auto mb-4 d-flex justify-content-center"> {/* Adjust column size and margin for horizontal gap */}
    <Button gradientDuoTone="pinkToOrange" onClick={() => { window.location.reload(); }}>All Orders</Button>
  </div>
</div>



      </div>
      <div className='mx-auto'>
        <CalendarTemplate dates={orderDatesAll} handleDateClick={handleDateClick} />
      </div>
 
      {orderDate == null && fadata.length == 0 && <p className='text-center text-primary text-3xl fw-bold mt-3'>No Orders yet</p>}
      {orderDate != null && <p className='text-center text-primary display-4 fw-bold'>Orders for {orderDate}</p>}
      {orderDate != null && fadata.length == 0 && <p className='text-center text-danger fw-bold'>No Orders on {orderDate}</p>}
      {fadata.length !== 0 && fadata.map(order => (
        <OrderItem key={order._id} order={order} onEdit={handleEditClick} onDelete={handleDeleteOrder} />
      ))}


      {/* Modal for adding an order */}
      {addOrder && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity backdrop-blur-md" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
      
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
      
                  <div className="logo-container">
                    <div className="brand-text">
                      <span>ADD ORDER</span>
                    </div>
                  </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="recipientName" className="block text-sm font-medium text-gray-700 mr-4 w-20 sm:w-32">
                      Recipient's Name<span className="text-red-500">*</span>
                    </label>
                    <TextInput
                      type="text"
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="recipientName"
                      name="recipientName"
                      value={formData.recipientName}
                      onChange={handleChange}
                      required
                      placeholder="Recipient Name"
                      rightIcon={FaRegUser}
                    />
                  </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="relationship" className="block text-sm font-medium text-gray-700 mr-4 w-20 sm:w-32">
                      Relationship<span className="text-red-500">*</span>
                    </label>
                    <TextInput
                      type="text"
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="relationship"
                      name="relationship"
                      value={formData.relationship}
                      onChange={handleChange}
                      required
                      placeholder="Relationship with recipient"
                      rightIcon={TbCirclesRelation}
                    />
                  </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="occasion" className="block text-sm font-medium text-gray-700 mr-4 w-20 sm:w-32">
                      Occasion<span className="text-red-500">*</span>
                    </label>
                    <TextInput
                      type="text"
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="occasion"
                      name="occasion"
                      value={formData.occasion}
                      placeholder="Christmas/Birthday/Anniversary"
                      onChange={handleChange}
                      required
                      rightIcon={GiPartyPopper}
                    />
                  </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="gender" className="block text-sm font-medium text-gray-700 mr-4 w-20 sm:w-32">
                      Gender<span className="text-red-500">*</span>
                    </label>
                    <TextInput
                      type="text"
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="gender"
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                      placeholder="Male/Female/Other"
                    />
                  </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700 mr-4 w-20 sm:w-32">
                      Address<span className="text-red-500">*</span>
                    </label>
                    <Textarea
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="address"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      required
                      placeholder="Address of the recipient..."
                      rows={2}
                    />
                  </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 mr-4 w-20 sm:w-32">
                      Phone Number<span className="text-red-500">*</span>
                    </label>
                    {/* <div className="flex items-center">
                      <TextInput
                        type="text"
                        className="p-2 w-16 sm:w-20 border border-gray-300 rounded-md"
                        id="countryCode"
                        name="countryCode"
                        placeholder="+1"
                        value={formData.countryCode}
                        onChange={handleChange}
                      /> */}
                      <TextInput
                        type="tel"
                        className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="+91 8765432109"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        required
                        minLength="10"
                        maxLength="10"
                        rightIcon={FaPhone}
                      />
                    </div>
                  {/* </div> */}
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mr-4 w-20 sm:w-32">
                      Email<span className="text-red-500">*</span>
                    </label>
                    <TextInput
                      type="email"
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="example@gmail.com"
                      required
                      rightIcon={FaEnvelope}
                    />
                  </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="interests" className="block text-sm font-medium text-gray-700 mr-4 w-20 sm:w-32">
                      Interests<span className="text-red-500">*</span>
                    </label>
                    <TextInput
                      type="text"
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="interests"
                      name="interests"
                      value={formData.interests}
                      placeholder="Electronics/PersonalizedItem/Branded"
                      onChange={handleChange}
                      required
                      rightIcon={AiFillLike}
                    />
                  </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="budget" className="block text-sm font-medium text-gray-700 mr-4 w-20 sm:w-32">
                      Budget: $<span className="text-red-500">*</span>
                    </label>
                    <TextInput
                      type="text"
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="budget"
                      name="budget"
                      value={formData.budget}
                      onChange={handleChange}
                      required
                      rightIcon={FaMoneyBillAlt}
                    />
                  </div>
      
                  <div className="mb-3">
                      <label className="form-label">Default Option:</label>
                      <div className="flex flex-wrap gap-2">
                        {['None', 'Flowers', 'Chocolate', 'Cake', 'Giftcard'].map((option, index) => (
                          <div className="form-check form-check-inline" key={option}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="defaultOption"
                              id={option}
                              value={option}
                              checked={formData.defaultOption === option}
                              onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor={option}>
                              {index === 0 && <div><RxShadowNone className="text-success" />
                                None</div>} {/* Checkmark for the 'None' option */}
                              {index === 1 && <div><GiFlowerEmblem className="text-purple" />Flower</div>} {/* Flower icon for 'Flowers' */}
                              {index === 2 && <div><GiChocolateBar className="text-brown" />Chocolate</div>} {/* Chocolate icon for 'Chocolate' */}
                              {index === 3 && <div><FaBirthdayCake className="text-warning" /> Cake</div>} {/* Cake icon for 'Cake' */}
                              {index === 4 && <div><FaGift className="text-primary" />
                                Gift</div>}{/* Gift icon for 'Giftcard' */}
                            </label>
                          </div>
                        ))}
                      </div>


                    </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="comments" className="block text-sm font-medium text-gray-700 mr-4 w-20 sm:w-32">
                      Comments<span className="text-red-500">*</span>
                    </label>
                    <Textarea
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="comments"
                      name="comments"
                      value={formData.comments}
                      onChange={handleChange}
                      placeholder="Comments..."
                      rows={2}
                    />
                  </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="date" className="block text-sm font-medium text-gray-700 mr-4 w-20 sm:w-32">
                      Date<span className="text-red-500">*</span>
                    </label>
                    <TextInput
                      type="date"
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="date"
                      name="date"
                      onChange={handleChange}
                      required
                    />
                  </div>
      
                </div>
              </div>
            </div>
      
            <div className="bg-gray-50 px-4 py-3 gap-2 sm:px-6 sm:flex sm:flex-row-reverse flex-col sm:gap-0">
              <Button gradientDuoTone="greenToBlue" onClick={() => handleOrder()} className="mb-1 md:mb-0">
                Order
              </Button>
              <Button gradientDuoTone="pinkToOrange" onClick={() => setaddOrder(null)} className="mb-1 md:mb-0">
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
      
      )}








      {/* Modal for editing an order */}
      {editOrder && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity backdrop-blur-md" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
      
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
      
                  <div className="logo-container">
                    <div className="brand-text">
                      <span>EDIT ORDER</span>
                    </div>
                  </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="recipientName" className="block text-sm font-medium text-gray-700 mr-4 w-20 sm:w-32">
                      Recipient's Name<span className="text-red-500">*</span>
                    </label>
                    <TextInput
                      type="text"
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="recipientName"
                      name="recipientName"
                      value={editOrder.recipientName}
                      onChange={(e) => setEditOrder({ ...editOrder, recipientName: e.target.value })}
                      required
                      placeholder="Recipient Name"
                      rightIcon={FaRegUser}
                    />
                  </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="relationship" className="block text-sm font-medium text-gray-700 mr-4 w-20 sm:w-32">
                      Relationship<span className="text-red-500">*</span>
                    </label>
                    <TextInput
                      type="text"
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="relationship"
                      name="relationship"
                      value={editOrder.relationship}
                      onChange={(e) => setEditOrder({ ...editOrder, relationship: e.target.value })}
                      required
                      placeholder="Relationship with recipient"
                      rightIcon={TbCirclesRelation}
                    />
                  </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="occasion" className="block text-sm font-medium text-gray-700 mr-4 w-20 sm:w-32">
                      Occasion<span className="text-red-500">*</span>
                    </label>
                    <TextInput
                      type="text"
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="occasion"
                      name="occasion"
                      value={editOrder.occasion}
                      placeholder="Christmas/Birthday/Anniversary"
                      onChange={(e) => setEditOrder({ ...editOrder, occasion: e.target.value })}
                      required
                      rightIcon={GiPartyPopper}
                    />
                  </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="gender" className="block text-sm font-medium text-gray-700 mr-4 w-20 sm:w-32">
                      Gender<span className="text-red-500">*</span>
                    </label>
                    <TextInput
                      type="text"
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="gender"
                      name="gender"
                      value={editOrder.gender}
                      placeholder="Male/Female/Other"
                      onChange={(e) => setEditOrder({ ...editOrder, gender: e.target.value })}
                      required
                    />
                  </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700 mr-4 w-20 sm:w-32">
                      Address<span className="text-red-500">*</span>
                    </label>
                    <Textarea
                      type="text"
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="address"
                      name="address"
                      value={editOrder.address}
                      onChange={(e) => setEditOrder({ ...editOrder, address: e.target.value })}
                      required
                      placeholder="Address of the recipient..."
                    />
                  </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 mr-4 w-20 sm:w-32">
                      Phone Number<span className="text-red-500">*</span>
                    </label>
                    <TextInput
                      type="tel"
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={editOrder.phoneNumber}
                      onChange={(e) => setEditOrder({ ...editOrder, phoneNumber: e.target.value })}
                      required
                      minLength="10"
                      maxLength="10"
                      rightIcon={FaPhone}
                      placeholder="e.g., +18765432109"
                    />
                  </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mr-4 w-20 sm:w-32">
                      Email<span className="text-red-500">*</span>
                    </label>
                    <TextInput
                      type="email"
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="email"
                      name="email"
                      value={editOrder.email}
                      rightIcon={FaEnvelope}
                      onChange={(e) => setEditOrder({ ...editOrder, email: e.target.value })}
                      placeholder="example@gmail.com"
                      required
                    />
                  </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="interests" className="block text-sm font-medium text-gray-700 mr-4 w-20 sm:w-32">
                      Interests<span className="text-red-500">*</span>
                    </label>
                    <TextInput
                      type="text"
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="interests"
                      name="interests"
                      value={editOrder.interests}
                      rightIcon={AiFillLike}
                      onChange={(e) => setEditOrder({ ...editOrder, interests: e.target.value })}
                      placeholder="Electronics/PersonalizedItem/Branded"
                      required
                    />
                  </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="budget" className="block text-sm font-medium text-gray-700 mr-16">
                      Budget: $<span className="text-red-500">*</span>
                    </label>
                    <TextInput
                      type="text"
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="budget"
                      name="budget"
                      value={editOrder.budget}
                      rightIcon={FaMoneyBillAlt}
                      onChange={(e) => setEditOrder({ ...editOrder, budget: e.target.value })}
                      required
                    />
                  </div>
      
                  <div className="mb-3">
                      <label className="form-label">Default Option:</label>
                      <div className="flex flex-wrap gap-2">
                        {['None', 'Flowers', 'Chocolate', 'Cake', 'Giftcard'].map((option, index) => (
                          <div key={option} className="form-check form-check-inline">
                          <input
                            className="form-radio text-primary focus:ring focus:ring-primary-200"
                            type="radio"
                            name="defaultOption"
                            id={option}
                            value={option}
                            checked={editOrder.defaultOption === option}
                            onChange={(e) => setEditOrder({ ...editOrder, defaultOption: e.target.value })}
                          />
                            <label className="form-check-label" htmlFor={option}>
                              {index === 0 && <div><RxShadowNone className="text-success" />
                                None</div>} {/* Checkmark for the 'None' option */}
                              {index === 1 && <div><GiFlowerEmblem className="text-purple" />Flower</div>} {/* Flower icon for 'Flowers' */}
                              {index === 2 && <div><GiChocolateBar className="text-brown" />Chocolate</div>} {/* Chocolate icon for 'Chocolate' */}
                              {index === 3 && <div><FaBirthdayCake className="text-warning" /> Cake</div>} {/* Cake icon for 'Cake' */}
                              {index === 4 && <div><FaGift className="text-primary" />
                                Gift</div>}{/* Gift icon for 'Giftcard' */}
                            </label>
                          </div>
                        ))}
                      </div>


                    </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="comments" className="block text-sm font-medium text-gray-700 mr-6">
                      Comments<span className="text-red-500">*</span>
                    </label>
                    <Textarea
                      type="text"
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="comments"
                      name="comments"
                      value={editOrder.comments}
                      onChange={(e) => setEditOrder({ ...editOrder, comments: e.target.value })}
                      placeholder="Enter comments..."
                      rows={2}
                    />
                  </div>
      
                  <div className="mb-4 flex flex-col sm:flex-row items-center">
                    <label htmlFor="date" className="block text-sm font-medium text-gray-700 mr-16">
                      Date<span className="text-red-500">*</span>
                    </label>
                    <TextInput
                      type="date"
                      className="mt-1 p-2 w-full sm:w-92 border border-gray-300 rounded-md"
                      id="date"
                      name="date"
                      value={editOrder.date}
                      onChange={(e) => setEditOrder({ ...editOrder, date: e.target.value })}
                      required
                    />
                  </div>
      
                </div>
              </div>
            </div>
      
            <div className="bg-gray-50 px-4 py-3 gap-2 sm:px-6 sm:flex sm:flex-row-reverse flex-col sm:gap-0">
              <Button gradientDuoTone="greenToBlue" onClick={() => handleUpdateOrder(editOrder)} className="mb-1 md:mb-0">
                Save changes
              </Button>
              <Button gradientDuoTone="pinkToOrange" onClick={() => setEditOrder(null)} className="mb-1 md:mb-0">
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
      
      )}
    </div>
  );
}
