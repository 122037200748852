
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import noteContext from '../Context/notes/noteContext';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Button } from 'flowbite-react';
import './styles/main.css';
import logo from './assests/giftedcitlogo-removebg-preview.png';

export default function Navbar() {
  let navigate = useNavigate();

  const navRef = useRef();
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [Visibility, setVisibility] = useState(false);

  const handlesignout = (e) => {
    e.preventDefault();
    localStorage.removeItem('authtoken');
    navigate('/signin');
    closeNavbar();
  };

  const { checkUser } = useContext(noteContext);

  const checkUserFunction = () => {
    if (!checkUser()) {
      localStorage.removeItem('authtoken');
    }
  };

  useEffect(() => {
    checkUserFunction();
  }, []);

  const closeNavbar = () => {
    setNavbarOpen(false);
  };

  const showNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  return (
    <header  className=' justify-between'>
      <Link to="/">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo-image" />
          <div className="brand-text">
            <span>GIFTEDICT</span>
          </div>
        </div>
      </Link>
      <button className="nav-btn " onClick={showNavbar}>
        <FaBars />
      </button>
    
            <nav ref={navRef} className={navbarOpen ? 'responsive_nav' : ''}>
             
              {localStorage.getItem('authtoken') ? (
                <>
                 <Link to="/profile" className="nav-link right-btn " onClick={closeNavbar}>
                Profile
              </Link>
                  <Link to="/orders" className="nav-link right-btn" onClick={closeNavbar}>
                    Orders
                  </Link>

                  <Link to="/" className="nav-link right-btn" onClick={(e) => handlesignout(e)}>
                    <Button gradientDuoTone="pinkToOrange">SignOut</Button>
                  </Link>
                  {/* <div className="logo-container hidden sm:block">
  <img src={logo} alt="Logo" className="logo-image" />
</div> */}

                </>
              ) : (
                
                <>
                  <Link to="/signin" className="nav-link right-btn" onClick={closeNavbar}>
                    <Button gradientDuoTone="pinkToOrange">SignIn</Button>
                  </Link>

                  <Link to="/signup" className="nav-link right-btn" onClick={closeNavbar}>
                    <Button gradientDuoTone="pinkToOrange">SignUp</Button>
                  </Link>
                  {/* <div className="logo-container lg:hidden">
                    <img src={logo} alt="Logo" className="logo-image" />
                  </div> */}
                </>
              )}
              {/* <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                <FaTimes />
              </button> */}
            </nav>

          
      
    </header>
  );
}
