import { BsLinkedin, BsFacebook, BsInstagram, BsTwitterX } from 'react-icons/bs';
import { Footer, Modal, TextInput, Textarea, Button } from 'flowbite-react';
import { useContext, useState } from 'react';
import {  FaTimes } from 'react-icons/fa';
import noteContext from '../Context/notes/noteContext';
import emailjs from '@emailjs/browser';

export default function FooterComponent() {

  const {host,Pemail,Pname ,setEmail,setName} = useContext(noteContext);

  const footerBackgroundColor = '#FFFFFF';
  
  const [showModal, setShowModal] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState('');
 const [details, setdetails] = useState("")
  const footerLinks = [
    { text: "We're Hiring", subject: "Hiring" },
    { text: "Become a Brand Partner", subject: "Brand Partner" },
    { text: "Support", subject: "Support" },
    { text: "Contact Us", subject: "Contact Us" },
  ];

  
  const handleLinkClick = (subject) => {
    setSelectedSubject(subject);
    setShowModal(true);
  };

  const handlesentNotes = async (note) => {
    try {
      await fetch(`${host}/api/auth/putNote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(note)
      });
      alert("Sent Successfully")
      setShowModal(false); // Close modal after submission
      setdetails("")


    } catch (error) {
      console.error("Error updating the order:", error);
    }
  };
  const handleSubmit = () => {
    const YOUR_SERVICE_ID=process.env.REACT_APP_YOUR_SERVICE_ID;
    const YOUR_TEMPLATE_ID=process.env.REACT_APP_YOUR_TEMPLATE_ID;
    const YOUR_PUBLIC_KEY =process.env.REACT_APP_YOUR_PUBLIC_KEY ;
    try{
    let note={ Pname, Pemail, type: selectedSubject , details };
    emailjs.send(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, {
      type: note.type,
      name: note.Pname,
      email: note.Pemail,
      details: note.details,
    }, YOUR_PUBLIC_KEY)
    .then((response) => {
      console.log('SUCCESS!');
  
    }, (err) => {
      console.log('FAILED...', err);
    });
    handlesentNotes(note)
  } catch (error) {
    console.error("Error updating the order:", error);
  }
   
  };

  return (
    <>

{showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <div className="flex justify-between items-center text-center">
                <p></p>
                <h3 className="text-lg leading-6 font-medium text-gray-900">{selectedSubject}</h3>
                <button onClick={() => setShowModal(false)}>
                  <FaTimes />
                </button>
              </div>
              <div className="mt-2">
  {/* <p className="text-sm text-gray-500">support@giftedict.com</p> */}
  <form className="flex flex-col gap-4">
    <div>
      {/* <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label> */}
      <TextInput
        id="name"
        type="text"
        value={Pname}
        onChange={(e) => setName(e.target.value)}
        placeholder="Name"
      />
    </div>

    <div>
      {/* <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label> */}
      <TextInput
        id="email"
        type="email"
        value={Pemail}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
    </div>

    <div>
      {/* <label htmlFor="details" className="block text-sm font-medium text-gray-700">Details</label> */}
      <Textarea
        id="details"
        value={details}
        onChange={(e) => setdetails(e.target.value)}
        placeholder="Details"
      />
    </div>

    <Button onClick={handleSubmit}>Submit</Button>
  </form>
</div>
            </div>
          </div>
        </div>
      )}
  
    <Footer bgDark style={{ backgroundColor: footerBackgroundColor }}>
   
      <div className="w-full row my-2">
      <div className='text-center'>
  <h2 data-testid="flowbite-footer-title" className="mb-1 text-sm font-semibold uppercase text-gray-500 dark:text-white">
    GiftEdict
  </h2>
</div>

        <div className='col-4'>
        <div className="bg-white text-center sm:flex sm:justify-between sm:px-4">
          <div className="text-xs sm:text-sm text-black mb-2 sm:mb-0">
            © {new Date().getFullYear()} Giftedict
          </div>
          </div>
        </div>
        <div className="gap-6 col-6
          flex flex-col md:flex-row justify-between">

          {/* <div>
            <Footer.Title title="Giftedict" className="text-sm" />
            <Footer.LinkGroup col>
              <div className="mt-2 flex space-x-3 sm:mt-0">
                <Footer.Icon href="https://www.instagram.com/giftedict?igsh=ODBydWt2YWphaWY4&utm_source=qr" target='_blank' icon={BsInstagram} />
                <Footer.Icon href="#" icon={BsLinkedin} />
                <Footer.Icon href="#" icon={BsTwitterX} />
              </div>
            </Footer.LinkGroup>
          </div> */}



          {/* <div>
            <Footer.Title title="How it works" className="text-sm" />
            <Footer.LinkGroup col className='my-0.5'>
              <Footer.Link href="#" className="my-1 text-xs">Platform overview</Footer.Link>
              <Footer.Link href="#" className="my-1 text-xs">Bulk Gifting</Footer.Link>
              <Footer.Link href="#" className="my-1 text-xs">Branded swag</Footer.Link>
              <Footer.Link href="#" className="my-1 text-xs">Pricing</Footer.Link>
              <Footer.Link href="#" className="my-1 text-xs">Gift API</Footer.Link>
            </Footer.LinkGroup>
          </div> */}
          {/* <div>
            <Footer.Title title="Use Cases" className="text-sm" />
            <Footer.LinkGroup col className='my-0.5'>
              <Footer.Link href="#" className="my-1 text-xs">Employee Recognition</Footer.Link>
              <Footer.Link href="#" className="my-1 text-xs">Client Appreciation</Footer.Link>
              <Footer.Link href="#" className="my-1 text-xs">Sales Prospecting</Footer.Link>
              <Footer.Link href="#" className="my-1 text-xs">EAs & Admins</Footer.Link>
              <Footer.Link href="#" className="my-1 text-xs">Custom Boxes</Footer.Link>
            </Footer.LinkGroup>
          </div> */}
          <div>
            
            <Footer.LinkGroup col className='mx-2 my-0.5'>
            <div className="d-flex flex-column flex-md-row my-0.5"> 
            {footerLinks.map((link, index) => (
                  <Footer.Link key={index} href="#" className="my-1 text-xs" onClick={() => handleLinkClick(link.subject)}>
                    {link.text}
                  </Footer.Link>
                ))}
          </div>

            </Footer.LinkGroup>

          </div>
        </div>

        {/* <div className="bg-white text-center sm:flex sm:justify-between sm:px-4">
          <div className="text-xs sm:text-sm text-black mb-2 sm:mb-0">
            © {new Date().getFullYear()} Giftedict
          </div>
          <div className="text-xs sm:text-sm text-black sm:flex space-x-2 sm:space-x-6 mb-2">
            <a href="#" className="hover:text-gray-900">Terms of Use</a>
            <a href="#" className="hover:text-gray-900">Privacy Policy</a>
            <a href="#" className="hover:text-gray-900">Policy Enterprise</a>
            <a href="#" className="hover:text-gray-900">Service Agreement</a>
          </div> 
        </div> */}
      </div>
    </Footer>
    </>
  );
}
