import React from 'react';
import { useSpring, animated, config } from 'react-spring';
import logo1 from "./assests/file-5SCI0fcbsUNSK7nBxwO9kMfp.jpg";
import logo2 from "./assests/file-9mL2tpV9rhuLwYQ43upboGhT.jpg";
import logo3 from "./assests/file-eM5nmFi2IX6fYMO3afMJ7fx7.jpg";
import logo4 from "./assests/file-qzZiciEMKqKVs1J5FSwTeo4h.jpg";
// import logo5 from "./assests/file-ZmWFwv219jXph9ZrFkG7UyX2.jpg";
import "./styles/marqrev.css";

const images = [logo1, logo2, logo3, logo4, logo1, logo2, logo3, logo4];

export default function MarqueeRev() {
  return (
    <div className=" container bg-contain mx-auto">
      <div className="marquee-card bg-contain">
        <div className="marquee">
          <div className="marquee-content bg-contain">
            {Array.from({ length: 12 }).map((_, index) => (
              <div key={index} className="marquee-item ">
                <div className="image-container">
                  <img src={images[index % images.length]} alt={`Image ${index + 1}`} className="marquee-image" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}