import React from 'react';
import { useSpring, animated, config } from 'react-spring';

import MarqueeRev from './MarqueeRev';
// import { FaKeyboard } from 'react-icons/fa';
// import { IoIosGift } from 'react-icons/io';
import logo1 from './assests/fimg4.jpg';
import logo2 from './assests/mainf.jpeg';
import logo3 from './assests/mainfig2.jpeg';

// Functional component for the Home page
export default function Home() {
  // Spring animations
  const fadeInLeft = useSpring({
    from: { opacity: 0, transform: 'translateX(-20px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
    config: config.slow,
  });

  const fadeInRight = useSpring({
    from: { opacity: 0, transform: 'translateX(20px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
    config: config.slow,
  });

  const fadeInUp = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: config.slow,
  });

  // Return JSX for the Home component
  return (
    <div>
      <div className="container">
        {/* First Section */}
        <div className="row mb-10 justify-between">
          <animated.div className="col-md-8 mt-6 d-flex flex-column justify-center text-center" style={fadeInLeft}>
            <div style={{fontWeight: '550'}} className='text-3xl md:text-6xl '>
              <div>
                We treat our customers as
                <span
                  style={{ background: 'linear-gradient(45deg, #f68f19, #e6c027)', WebkitBackgroundClip: 'text', color: 'transparent', margin: '8px' }}
                >
                  Royals
                </span>
                and
                their orders as
                <span
                  style={{ background: 'linear-gradient(45deg, #f68f19, #e6c027)', WebkitBackgroundClip: 'text', color: 'transparent', margin: '5px' }}
                >
                  Edict
                </span>
              </div>
            </div>
            <div 
            className='mt-4 text-1xl md:text-2xl '>
              Your Personal Gift Concierge - Making Moments Memorable
            </div>
          </animated.div>
          <animated.div className="col-md-4 mt-3" style={fadeInRight}>
            <div className="card  rounded-md shadow-sm hover-shadow-lg transition duration-300 ease-in-out transform hover-scale-105 bg-contain">
              <img
                src={logo1}
                alt="Card Image"
                className="w-full h-full object-cover rounded-md "
              />
            </div>
          </animated.div>
        </div>
<br className='md:block hidden'/>
        {/* Second Section */}
        <div className="row mt-10 mb-10 justify-center items-center">
  <div className="col-md-8">
    <animated.div className="desc1logo-container text-center mt-2 justify-center items-center text-3xl md:text-5xl" style={{ ...fadeInUp, textDecoration: 'underline' }}>
      How it works
    </animated.div>
  </div>
</div>

        <br className='md:block hidden'/>
        {/* Third Section */}
        <div className="row mt-10 justify-between"  >
          <animated.div className="col-md-4" style={fadeInLeft}>
            <div className="card  rounded-md shadow-sm hover-shadow-lg transition duration-300 ease-in-out transform hover-scale-105">
              <img
                src={logo2}
                alt="Card Image"
                className="w-full h-[100%] object-cover rounded-md "
              />
            </div>
          </animated.div>

          <animated.div className="col-md-8 mt-5 d-flex flex-column justify-center " style={fadeInRight}>
            
            <div style={{  fontWeight: '550', marginLeft: '10px', marginRight: '10px' }} className='text-3xl md:text-6xl'>
            {/* <FaKeyboard style={{ fontSize: '28px', marginLeft: '10px', marginRight: '10px'  }} className="mb-2 md:mb-0 md:mr-2 md:block hidden" /> */}
              <div>
              <animated.div className="desc1logo-container text-center mt-2 justify-center items-center text-3xl md:text-5xl " style={fadeInUp} >
              Get Started and
            </animated.div>
                {/* Get Started and
                <br /> */}
                {/* Enter Recipient Information */}
                <animated.div className="desc1logo-container text-center mt-2 justify-center items-center text-3xl md:text-5xl " style={fadeInUp} >
                Enter Recipient Information
            </animated.div>
                <div >

                </div>
              </div>
            </div>
            <div style={{  marginLeft: '10px', marginRight: '10px' }}   className='mt-4 text-1xl md:text-2xl '>
              Jump right in! It's easy to get started. Just tell us who you'd like to share with, and we'll guide you through the rest
            </div>
          </animated.div>
        </div>

        <div className="row mt-5 mb-2 justify-content-center">
  <div className="image12-container">
    <img src="arrow.jpeg" className="animated-image" alt="Arrow" />
  </div>
</div>

        <div className="row mt-5 mb-7 justify-between"  >
          
          <animated.div className="col-md-7 mt- d-flex flex-column justify-center" style={fadeInLeft}>
            <div className="icon-container mb-3">
              {/* <IoIosGift style={{ fontSize: '28px' }} className="mb-2 md:mb-0 md:mr-2 md:block hidden" /> */}
              <div style={{fontWeight: '550' }} className='text-3xl md:text-6xl '>
           
            <animated.div className="desc1logo-container text-center mt-2 justify-center items-center text-3xl md:text-5xl " style={fadeInUp} >
            Spend Smart,
            </animated.div>
            <animated.div className="desc1logo-container text-center mt-2 justify-center items-center text-3xl md:text-5xl " style={fadeInUp} >
            Gift Right
            </animated.div>
                {/* <div>Spend Smart,</div>
                <div className='mt-2'>Gift Right</div> */}
              </div>
            </div>
            <div  className='mt-4 text-1xl md:text-2xl '>
              Set a budget that works for you. No more overspending. Define your comfortable spending limit and stick to it with ease.
            </div>
          </animated.div>
          <animated.div className="col-md-4" style={fadeInRight}>
            <div className="card rounded-md shadow-sm hover-shadow-lg transition duration-300 ease-in-out transform hover-scale-105 bg-contain lg:block">
              <img
                src={logo3}
                alt="Card Image"
                className="w-full object-cover rounded-md  md:w-full sm:w-50 sm:h-auto" // Adjust the class for responsive size
              />
            </div>
          </animated.div>
        </div>
<br/>

   {/* Fourth row*/}
        <div className="row mt-10 flex justify-center">
          <div className="col-md-8 content-center bg-contain mt-3 mb-5 p-2 custom-outer-container" style={{ backgroundColor: '#fff7d5', borderRadius: '10px' }}>
            <div className="row ">
              <div className="text-center" style={{ fontSize: '25px', background: 'linear-gradient(45deg, #FB6848, #CEA80F)', WebkitBackgroundClip: 'text', color: 'transparent', margin: '5px' }}>
                Find the Perfect Present
              </div>
            </div>
            <div className="row text-center">
              <div style={{ fontSize: '34px', fontWeight: '600', marginLeft: '10px', marginRight: '10px' }}>
                This Month's Top Picks
              </div>
            </div>

            {/* Fifth row */}
            <div className="row mt-2 ">
              
              <div className="col-md-12">
                {/* Adjust the length of the marquee container */}
                <MarqueeRev />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
