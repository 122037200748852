import React from 'react';
import { useContext, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import noteContext from '../Context/notes/noteContext';
import { Button ,TextInput } from 'flowbite-react';
import logo from "./assests/giftedcitlogo-removebg-preview.png"
import { FaEye, FaEyeSlash } from 'react-icons/fa';

// Define a functional component for the Login page
export default function Login() {
  // Initialize React Router's navigation hook
  const navigate = useNavigate();
  // Access the 'host' property from the context using useContext
  const { host } = useContext(noteContext);

  // State to manage user input for email and password
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  // Function to handle user sign-in
  const signin = async (e) => {
    try {
      e.preventDefault();
      // Fetch request to the server for user authentication
      const response = await fetch(`${host}/api/auth/loginuser`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          email: credentials.email,
          password: credentials.password,
        }),
      });
      // Parse the response as JSON
      let res = await response.json();
      // Check if the authentication was successful
      if (res.success) {
        // Store the authentication token in local storage
        localStorage.setItem('authtoken', res.authtoken);
        // Navigate to the home page upon successful sign-in
        navigate('/orders');
      } else {
        // Display an alert for invalid details
        alert('Enter valid details');
      }
    } catch (error) {
      // Display any caught errors in the 'signin_error' element
      document.getElementById('signin_error').innerHTML = error;
    }
  };

  // Function to handle input changes and update state
  const handleOnChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  // Render the Login component
  return (
    <>
      {/* Container for the login form */}
      <div className='container  mt-1 mx-auto p-4 mb-7'>

        {/* Form for user login */}
        <form className='max-w-md mx-auto bg-white rounded-md overflow-hidden shadow-lg'>
          {/* Form content */}
          <div className='px-6 py-4'>
            {/* Logo and brand text */}
            <div className='nav-link right-btn flex justify-center items-center'>
              <img src={logo} alt="Logo" className="logo-image" />
            </div>
      
          
              <div className="logo-container nav-link right-btn flex justify-center items-center">
                {/* <img src={logo} alt="Logo" className="logo-image" /> */}
                <div className="sibrand-text">
                  <span>  SIGN IN</span>
                </div>
              </div>
            {/* Email input field */}
            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='email'>
              Email address
            </label>
            <TextInput
              type='email'
              name='email'
              // className='w-full p-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring focus:border-blue-300'
              id='email'
              onChange={handleOnChange}
              placeholder='Enter email'
            />

            {/* Password input field */}
            <div className='my-6'>
            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='password'>
              Password
            </label>
           <div className='relative  w-full'>
  <TextInput
    type={showPassword ? 'text' : 'password'}
    name='password'
    
    id='password'
    onChange={handleOnChange}
    placeholder='Enter password'
    style={{ paddingRight: '40px' }} 
    // Ensure space for the eye icon
  />
  <button 
  type="button"
  onClick={togglePasswordVisibility} 
  className="absolute top-1/2 transform -translate-y-1/2 right-2"// Adjust top value to move the icon slightly below the center
>
  {showPassword ? <FaEyeSlash /> : <FaEye />}
</button>
</div>
    <div className="text-right my-2">
    <button 
      type="button" 
      className="btn btn-link p-0 mb-4 text-sm" 
      onClick={() => {navigate("/forgotpass")}}>
      Forgot Password?
    </button>
  </div>
</div>

            {/* Sign-in button with a Link to trigger the sign-in function */}
            <Link to="/signin" className="nav-link right-btn flex justify-center items-center" onClick={signin}>
  <Button gradientDuoTone="pinkToOrange">SignIn</Button>
</Link>

          </div>
        </form>

        {/* Display any sign-in errors */}
        <p id='signin_error' className='text-red-500 text-center mt-2'></p>
      </div>
    </>
  );
}
