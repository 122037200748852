import React, { useState, useEffect, useContext } from 'react';
import noteContext from '../Context/notes/noteContext';
import { useNavigate } from 'react-router-dom';
import { TextInput, Button,Select } from 'flowbite-react';
import { useSpring } from '@react-spring/core';
import { animated } from "@react-spring/web"// Import animated and useSpring from @react-spring/web
import { FaUser, FaEnvelope, FaMapMarkerAlt, FaPhone, FaCreditCard } from 'react-icons/fa';
import logo from "./assests/ProfileGift.png";

function Profile() {
  let navigate = useNavigate();

  const [profileData, setProfileData] = useState({
    name: '',
    email: '',
    address: '',
    phno: '',
    cardHolderName: '',
    cardNumber: '',
    cvv: '',
    cardExpirationMonth: '',
    cardExpirationYear: '',
    billingZipCode: '',
  });

  const { checkUser, host } = useContext(noteContext);

  const checkUserFunction = async () => {
    let res = await checkUser();
    console.log(res);
    if (!res) {
      navigate('/signin');
    }
  };

  useEffect(() => {

    try {
    checkUserFunction();
    const fetchProfileData = async () => {
      try{
      const response = await fetch(`${host}/api/auth/getUserDetails`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': localStorage.getItem('authtoken'),
        },
      });
      const data = await response.json();

      if (!data.success) {
        console.error('Failed to fetch profile data');
        return;
      }

      setProfileData(data.data);
    } catch (error) {
      console.log(error)
    }
    };

    fetchProfileData();

  } catch (error) {
    console.log(error)
  }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    try {

    e.preventDefault();
    const response = await fetch(`${host}/api/auth/updateUser`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('authtoken'),
      },
      body: JSON.stringify(profileData),
    });
    const result = await response.json();

    if (!result.success) {
      console.error('Failed to update profile');
      return;
    }

    console.log(result);
    setShowModal(true);
    
  } catch (error) {
    console.log(error)
  }
  };

  // Animation using react-spring
  const imageAnimation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(-50px)' },
  });
  
  const [showModal, setShowModal] = useState(false);



    const handleProceed = () => {
        setShowModal(false);
        navigate('/orders');
    };

    const handleStay = () => {
        setShowModal(false);
    };

  return (
    <div className='container   p-4'>
      {showModal && (
                <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Profile Updated Successfully</h5>
                                <button type="button" className="btn-close" onClick={handleStay}></button>
                            </div>
                            <div className="modal-body">
                                <p>Do you want to proceed to the Order Page?</p>
                            </div>
                            <div className="modal-footer d-flex justify-content-between">
                                <button type="button" className="btn btn-secondary text-black" onClick={handleStay}>
                                    Stay Here
                                </button>
                                <button type="button" className="btn btn-primary text-black" onClick={handleProceed}>
                                    Proceed to Order Page
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
      <div className='shadow-lg rounded-md p-6'>
        <div className='row'>
          <div className='col-lg-1'></div>
          <div className='mt-8 lg:mt-20 col-lg-4 col-12 order-lg-1 order-2 mb-4 mb-lg-0'>
            <animated.img
              src={logo}
              alt='User'
              className='w-40 md:w-52 h-auto rounded-full object-cover d-none d-lg-block mx-auto md:mx-0'
              style={imageAnimation}
            />
          </div>
          <div className='col-lg-6 col-12 order-lg-2 order-1'>
            <h2 className='text-3xl font-bold mb-4'>Profile</h2>
            <form>


              <label htmlFor='name' className='block text-sm font-medium text-gray-600'>
                UserName
              </label>
              <div className='mb-4 flex items-center'>
                <TextInput
                  id='name'
                  name='name'
                  value={profileData.name}
                  onChange={handleChange}
                  rightIcon={FaUser}
                  className='mt-1 p-2 border rounded-md w-full'
                />
              </div>

              <label htmlFor='email' className='block text-sm font-medium text-gray-600'>
                Email
              </label>
              <div className='mb-4 flex items-center'>
                <TextInput
                  id='email'
                  name='email'
                  value={profileData.email}
                  rightIcon={FaEnvelope}
                  disabled
                  className='mt-1 p-2 border rounded-md w-full'
                />
              </div>

              <label htmlFor='address' className='block text-sm font-medium text-gray-900'>
                Address
              </label>
              <div className='mb-4 flex items-center'>
                <TextInput
                  id='address'
                  name='address'
                  value={profileData.address}
                  onChange={handleChange}
                  rightIcon={FaMapMarkerAlt}
                  className='mt-1 p-2 border rounded-md w-full'
                />
              </div>

              <label htmlFor='phno' className='block text-sm font-medium text-gray-800'>
                phone
              </label>
              <div className='mb-4 flex items-center'>
                <TextInput
                  id='phno'
                  name='phno'
                  value={profileData.phno}
                  onChange={handleChange}
                  rightIcon={FaPhone}
                  className='mt-1 p-2 border rounded-md w-full'
                />
              </div>


              <label htmlFor='creditCard' className='block text-sm font-medium text-gray-800 my-4'>
                <b>
                CreditCard Details
                </b>
              </label>
              <label htmlFor='cardHolderName' className='block text-sm font-medium text-gray-800'>
        Cardholder's Name
      </label>
      <TextInput
        id='cardHolderName'
        name='cardHolderName'
        value={profileData.cardHolderName}
        onChange={handleChange}
        rightIcon={FaUser}
        className='mb-4 mt-1 p-2 border rounded-md w-full'
      />

      <p className='text-secondary my-2'>Card Number and cvv is not shown again, if you want to update enter details again.</p>
      <label htmlFor='cardNumber' className='block text-sm font-medium text-gray-800'>
        Card Number
      </label>
      <TextInput
        id='cardNumber'
        name='cardNumber'
        value={profileData.cardNumber}
        onChange={handleChange}
        rightIcon={FaCreditCard}
        className='mb-4 mt-1 p-2 border rounded-md w-full'
      />

      <label htmlFor='cvv' className='block text-sm font-medium text-gray-800'>
        CVV
      </label>
      <TextInput
        id='cvv'
        name='cvv'
        value={profileData.cvv}
        onChange={handleChange}
        className='mb-4 mt-1 p-2 border rounded-md w-full'
      />


<div className="grid gap-4 grid-cols-2">
        <div>
          <label htmlFor='cardExpirationMonth' className='block text-sm font-medium text-gray-800'>
            Expiration Month
          </label>
          <Select
            id='cardExpirationMonth'
            name='cardExpirationMonth'
            value={profileData.cardExpirationMonth}
            onChange={handleChange}
            className='mb-4 mt-1 p-2 border rounded-md w-full'
          >
            {/* Assuming you're using a component library that provides a Select component */}
            {/* Placeholder for months */}
            <option value="">Month</option>
            {[...Array(12).keys()].map(month => (
              <option key={month} value={month + 1}>
                {month + 1}
              </option>
            ))}
          </Select>
        </div>

        <div>
          <label htmlFor='cardExpirationYear' className='block text-sm font-medium text-gray-800'>
            Expiration Year
          </label>
          <Select
            id='cardExpirationYear'
            name='cardExpirationYear'
            value={profileData.cardExpirationYear}
            onChange={handleChange}
            className='mb-4 mt-1 p-2 border rounded-md w-full'
          >
            <option value="">Year</option>
            {Array.from(new Array(20), (val, index) => new Date().getFullYear() + index).map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
        </div>
      </div>

      {/* <label htmlFor='cardExpirationDate' className='block text-sm font-medium text-gray-800'>
        Card Expiration Date
      </label>
      <TextInput
        id='cardExpirationDate'
        name='cardExpirationDate'
        value={profileData.cardExpirationDate}
        onChange={handleChange}
        className='mb-4 mt-1 p-2 border rounded-md w-full'
      /> */}

      <label htmlFor='billingZipCode' className='block text-sm font-medium text-gray-800'>
        Billing ZIP Code
      </label>
      <TextInput
        id='billingZipCode'
        name='billingZipCode'
        value={profileData.billingZipCode}
        onChange={handleChange}
        className='mb-4 mt-1 p-2 border rounded-md w-full'
      />
              {/* <div className='mb-4 flex items-center'>
                <TextInput
                  id='creditCard'
                  name='creditCard'
                  value={profileData.creditCard}
                  onChange={handleChange}
                  rightIcon={FaCreditCard}
                  className='mt-1 p-2 border rounded-md w-full'
                />
              </div> */}
              <div className='mb-4'>
                <Button gradientDuoTone="pinkToOrange" onClick={handleSubmit} className='bg-blue-500 text-white px-4 py-2 rounded-md'>
                  Update Profile
                </Button>
              </div>
              
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
