import { useState } from "react";
import noteContext from "./noteContext";



const NoteState = (props) => {

  const host=process.env.REACT_APP_BACK_END_HOST;

  // const host ="http://localhost:5000";

  const checkUser = async () => {
    try {

      const response = await fetch(`${host}/api/auth/check`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          "auth-token": localStorage.getItem("authtoken")
        },
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body data type must match "Content-Type" header
      });
      let res = await response.json(); // parses JSON response into native JavaScript objects
      if (res.success) {
        return true

      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  }
  const [isProfileUpdate, setisProfileUpdate] = useState(true)
  const [Pemail, setEmail] = useState('');
  const [Pname, setName] = useState('');

  const checkProfile = async () => {
    try {

    const auth_token = localStorage.getItem('authtoken');
    const response = await fetch(`${host}/api/auth/isProfileFilled`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'auth-token': auth_token
      },
    });
    const data = await response.json();
    console.log(data)
    setisProfileUpdate(data.isProfileFilled)
    setEmail(data.email)
    setName(data.name)
   

  } catch (error) {
    console.log(error)
  }
  };

  return (
    <noteContext.Provider value={{ host, checkUser ,checkProfile,isProfileUpdate,Pemail,Pname,setEmail,setName}}>
      {props.children}
    </noteContext.Provider>
  )
}

export default NoteState